body * {
  font-size: 15px !important;
}

.login.login-v2 .login-header .brand {
  font-size: 28px !important;
}

.login.login-v2 .login-header .brand .logo {
  font-size: 0 !important;
}

.form-control {
  appearance: auto !important;
}

.btn-default {
  color: #212529 !important;
  background-color: #b4c3cc !important;
  border-color: #f1f3f4 !important;
}

.btn-white {
  color: black!important;
  background-color: #fff!important;
  border: 1px solid #0c0e10!important;
}

#tblUserWrapper table tbody td:nth-child(2),
#tblUserModelWrapper table tbody td:nth-child(2) {
  word-break: break-all;
}
